import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  ListItemButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import StoreIcon from "@mui/icons-material/Store";
import riseGroupLogoWhite from "../../assets/logo/riseGroupLogoWhite.png";
import Logo from "../../assets/logo/Logo.svg";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

const Sidebar = () => {
  const [open, setOpen] = useState({
    company: false,
    accounting: false,
    businessSupport: false,
    legalSupport: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(2);
  const navigate = useNavigate();
  const [storageValue, setStorageValue] = useState(() => {
    return localStorage.getItem("activeForm") || "";
  });

  const handleClick = (section) => {
    setOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    if (route) {
      navigate(route);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("activeForm");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("useremail");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyName");
    localStorage.removeItem("registeredEmail");
    navigate("/login");
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = localStorage.getItem("activeForm");
      setStorageValue(newValue);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#0F0049",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        height: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <img src={Logo} alt="Logo" width={"200px"} />
        <Typography variant="body2" sx={{ color: "#8A99AF" }} mt={4}>
          MENU
        </Typography>

        <List component="nav">
          {storageValue === "isFormCompleted" && (
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 1}
                onClick={() => handleListItemClick(1, "/dashboard")}
                sx={{
                  backgroundColor:
                    selectedIndex === 1 ? "#080028 !important" : "transparent",
                  padding: "0.5rem",
                  borderRadius: "0.25rem",
                }}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <GridViewIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{
                    color: "#FFFFFF",
                    marginLeft: "0.25rem",
                    wordBreak: "break-word",
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={() => {
                handleClick("company");
                handleListItemClick(2, "/company");
              }}
              sx={{
                backgroundColor:
                  selectedIndex === 2 ? "#080028 !important" : "transparent",
                padding: "0.5rem",
                borderRadius: "0.25rem",
              }}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <StoreIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary="Company"
                sx={{
                  color: "#FFFFFF",
                  marginLeft: "0.25rem",
                  wordBreak: "break-word",
                }}
              />
              {/* {open.company ? (
                <ExpandLess sx={{ color: "#FFFFFF" }} fontSize="small" />
              ) : (
                <ExpandMore sx={{ color: "#FFFFFF" }} fontSize="small" />
              )} */}
            </ListItemButton>
          </ListItem>

          {/* <Collapse in={open.company} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  pl={4}
                  selected={selectedIndex === 3}
                  onClick={() => handleListItemClick(3, "/company")}
                  sx={{
                    backgroundColor:
                      selectedIndex === 3 ? "#080028" : "transparent",
                  }}
                >
                  <ListItemText
                    primary="Incorporation"
                    sx={{ color: "#8A99AF" }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  pl={4}
                  selected={selectedIndex === 4}
                  onClick={() => handleListItemClick(4)}
                  sx={{
                    backgroundColor:
                      selectedIndex === 4 ? "#080028" : "transparent",
                  }}
                >
                  <ListItemText primary="Details" sx={{ color: "#8A99AF" }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  pl={4}
                  selected={selectedIndex === 5}
                  onClick={() => handleListItemClick(5)}
                  sx={{
                    backgroundColor:
                      selectedIndex === 5 ? "#080028" : "transparent",
                  }}
                >
                  <ListItemText primary="Documents" sx={{ color: "#8A99AF" }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse> */}

          {storageValue === "isFormCompleted" && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 6}
                  onClick={() => {
                    handleListItemClick(6, "/company/accounting");
                  }}
                  sx={{
                    backgroundColor:
                      selectedIndex === 6
                        ? "#080028 !important"
                        : "transparent",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <StoreIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Accounting"
                    sx={{
                      color: "#FFFFFF",
                      marginLeft: "0.25rem",
                      wordBreak: "break-word",
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 8}
                  onClick={() => {
                    handleListItemClick(8, "/company/advisory");
                  }}
                  sx={{
                    backgroundColor:
                      selectedIndex === 8
                        ? "#080028 !important"
                        : "transparent",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <StoreIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Advisory"
                    sx={{
                      color: "#FFFFFF",
                      marginLeft: "0.25rem",
                      wordBreak: "break-word",
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 10}
                  onClick={() => {
                    handleListItemClick(10, "/company/legalSupport");
                  }}
                  sx={{
                    backgroundColor:
                      selectedIndex === 10
                        ? "#080028 !important"
                        : "transparent",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <StoreIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Legal Support"
                    sx={{
                      color: "#FFFFFF",
                      marginLeft: "0.25rem",
                      wordBreak: "break-word",
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 12}
                  onClick={() => {
                    handleListItemClick(12, "/company/software");
                  }}
                  sx={{
                    backgroundColor:
                      selectedIndex === 12
                        ? "#080028 !important"
                        : "transparent",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <StoreIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Software"
                    sx={{
                      color: "#FFFFFF",
                      marginLeft: "0.25rem",
                      wordBreak: "break-word",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Box>

      <ListItem disablePadding>
        <ListItemButton
          selected={selectedIndex === 14}
          onClick={handleLogout}
          sx={{
            backgroundColor:
              selectedIndex === 14 ? "#080028 !important" : "transparent",
            padding: "0.5rem",
            borderRadius: "0.25rem",
          }}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <LogoutIcon sx={{ color: "#FFFFFF" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{
              color: "#FFFFFF",
              marginLeft: "0.25rem",
              wordBreak: "break-word",
            }}
          />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default Sidebar;
