import { Box, Button, Typography, Grid } from "@mui/material";
import React from "react";
import riseGroupLogo from "../../assets/logo/riseGroupLogo.png";
import successLikeLogo from "../../assets/logo/successLike.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import tri1 from "../../assets/logo/tri1.png";
import tri2 from "../../assets/logo/tri2.png";
import tri3 from "../../assets/logo/tri3.png";
import Logo from "../../assets/logo/risegroupblack.svg";

const SuccessPage = ({ onBack }) => {
  const location = useLocation();
  const packageState = useSelector((state) => state.package?.data);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/company");
  };

  const {
    successText = `Thank you for submitting your details. We will now process your incorporation with Companies House and get back to you once your request has been approved.`,
  } = location?.state || {};

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      sx={{
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
        mt: 5,
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        sx={{
          maxWidth: "1440px",
          // minHeight: "100vh",
          margin: "0 auto",
        }}
      >
        {/* <img
          src={tri1}
          alt="tri1"
          width={"100px"}
          style={{
            position: "absolute",
            left: "12%",
            top: "45%",
            opacity: "0.3",
            transform: "rotate(-35deg)",
          }}
        /> */}
        {/* <img
          src={tri2}
          alt="tri2"
          width={"100px"}
          style={{
            position: "absolute",
            opacity: "0.3",
            right: "12%",
            top: "75%",
          }}
        /> */}
        {/* <img
          src={tri3}
          alt="tri3"
          width={"100px"}
          style={{ position: "absolute", right: "12%", top: "10%" }}
        /> */}
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          width={"100%"}
          maxHeight={"666px"}
          maxWidth={"726px"}
          p={4}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "1rem",
            margin: "1rem 0",
          }}
        >
          <img
            src={Logo}
            alt="company-logo"
            style={{
              width: "100%",
              maxHeight: "50px",
              maxWidth: "250px",
            }}
          />
          <img
            src={successLikeLogo}
            alt="success-logo"
            style={{
              maxHeight: "184px",
              maxWidth: "184x",
              marginTop: "4rem",
              marginBottom: "2rem",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#0F0049",
              textAlign: "center",
              width: "100%",
              maxHeight: "67px",
              maxWidth: "550x",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
            dangerouslySetInnerHTML={{
              __html: successText,
            }}
          />
          <Box mt={3} display="flex">
            {/* <Button
              sx={{
                marginTop: "1rem",
                textTransform: "none",
                backgroundColor: "#ffffff",
                color: "#cfcfcf",
                border: "1px solid #cfcfcf",
                borderRadius: "1rem",
                padding: "0.4rem 1.5rem",
                fontWeight: 600,
                "&:hover": {
                  color: "#2069F8",
                  border: "1px solid #2069F8",
                },
              }}
              onClick={onBack}
            >
              Previous
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessPage;
