import React from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import JumpAccountingLogo from "../../assets/logo/JumpAccountingLogo.png";
import ExtraTicks from "../../assets/ExtraTicks.svg";
import Julien from "../../assets/Julien.png";
import Xero from "../../assets/logo/Xero.svg";

const AccountingPage = () => {
  const services = [
    "Personal chartered accountant",
    "Year end accounts",
    "Bookkeeping",
    "Corporation Tax Filing (CT600)",
    "Confirmation Statement Filing",
    "Xero Account",
    "Quarterly management accounts",
    "VAT returns & filing",
    "Payroll",
    "Director self assessment filing",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: 6,
        p: 4,
        bgcolor: "#f4f7fb",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Box sx={{ mb: 4 }}>
          <img
            src={JumpAccountingLogo}
            alt="JumpAccountingLogo"
            width={"200px"}
            style={{ cursor: "pointer" }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.02)";
              e.currentTarget.style.opacity = "0.6";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.opacity = "1";
            }}
            onClick={() => {
              window.open("https://www.jumpaccounting.co.uk/", "_blank");
            }}
          />
          <Typography sx={{ fontSize: "1.1rem" }}>
            Ensure you remain compliant with HMRC & Companies House with our
            team of Accountants.
          </Typography>
        </Box>
        <Button
          sx={{
            borderRadius: "5px",
            height: "40px",
            backgroundColor: "#2468FF",
            color: "#ffffff",
            marginTop: "1rem",
            marginLeft: "0.5rem",
            fontWeight: 600,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#b4a4f5",
            },
          }}
          onClick={() => {
            window.open("https://jumpaccounting.co.uk", "_blank");
          }}
        >
          Find out more
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        {/* Left side - Services List */}
        <Box
          sx={{
            flex: 1,
            p: 3,
            bgcolor: "#FFFFFF",
            borderRadius: "10px",
            mr: 8,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Choose the Accounting Services you need
          </Typography>
          <List>
            {services.map((service, index) => (
              <ListItem key={index} sx={{ padding: "0.25rem 0", mt: 0.5 }}>
                {/* <Checkbox checked sx={{ color: "#0E76FD" }} /> */}
                <img src={ExtraTicks} alt="ExtraTicks" width={"35px"} />
                <Typography sx={{ ml: 2, fontSize: "1.1rem" }}>
                  {service}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "500px",
          }}
        >
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              borderRadius: "10px",
              p: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "250px",
              }}
            >
              <Typography
                sx={{ fontSize: "1.1rem", fontWeight: "medium" }}
                gutterBottom
              >
                Our team are on hand to discuss how we can best support you.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "5px",
                  height: "40px",
                  backgroundColor: "#2468FF",
                  color: "#ffffff",
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  fontWeight: 600,
                  padding: "1rem 2rem",
                  textTransform: "none",
                  width: "max-content",
                  "&:hover": {
                    backgroundColor: "#b4a4f5",
                  },
                }}
                onClick={() => {
                  window.open(
                    "https://meetings.hubspot.com/julien-smith",
                    "_blank"
                  );
                }}
              >
                Book a call
              </Button>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={Julien}
                alt="Team member"
                style={{
                  width: "120px",
                  height: "125px",
                  borderRadius: "5%",
                  marginRight: "1rem",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "#0378C8",
              borderRadius: "10px",
              p: 3,
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "250px",
              }}
            >
              <Typography
                sx={{ fontSize: "1.1rem", fontWeight: "medium", color: "#fff" }}
                gutterBottom
              >
                Claim your discounted Xero Accounting Software package today.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "5px",
                  height: "40px",
                  backgroundColor: "#fff",
                  color: "#000",
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  fontWeight: 600,
                  padding: "1rem 2rem",
                  textTransform: "none",
                  width: "max-content",
                  "&:hover": {
                    backgroundColor: "#b4a4f5",
                  },
                }}
                onClick={() => {
                  window.open(
                    "https://xero5440.partnerlinks.io/xero-discounted-pricing-plans",
                    "_blank"
                  );
                }}
              >
                Find out more
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <img
                src={Xero}
                alt="Team member"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  marginRight: "1rem",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountingPage;
