import React from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import Logo from "../../assets/logo/55Logo.png";
import FiftyFiveTeam from "../../assets/55team.png";

const SoftwarePage = () => {
  return (
    <Box sx={{ ml: 6, p: 4, backgroundColor: "#f5f7fa", minHeight: "100vh" }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Box sx={{ mb: 4 }}>
          <img
            src={Logo}
            alt="Logo"
            width={"200px"}
            style={{ cursor: "pointer" }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.02)";
              e.currentTarget.style.opacity = "0.6";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.opacity = "1";
            }}
            onClick={() => {
              window.open("https://fiftyfivetech.uk", "_blank");
            }}
          />
          <Typography sx={{ mt: 2, fontSize: "1.1rem" }}>
            Fast track your business with access to high-quality software
            developers at a cost effective rate.
          </Typography>
        </Box>
        <Button
          sx={{
            borderRadius: "5px",
            height: "40px",
            backgroundColor: "#2468FF",
            color: "#ffffff",
            marginTop: "1rem",
            marginLeft: "0.5rem",
            fontWeight: 600,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#b4a4f5",
            },
          }}
          onClick={() => {
            window.open("https://fiftyfivetech.uk", "_blank");
          }}
        >
          Find out more
        </Button>
      </Box>

      <Grid container spacing={4} sx={{ width: "100%", maxWidth: "1200px" }}>
        {/* MVP Development and Hire Developers Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              backgroundColor: "#2468FE",
              color: "#fff",
              textAlign: "center",
              padding: "4rem",
              height: "30vh",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              MVP Development
            </Typography>
            <Typography variant="body1" sx={{ my: 2, height: "5vh" }}>
              Investor-ready MVP development in just 8 weeks.
            </Typography>
            <Button
              sx={{
                backgroundColor: "#2468FE",
                border: "1px solid white",
                color: "#fff",
                textAlign: "center",
                borderRadius: "8px",
                mt: 2,
              }}
              onClick={() => {
                window.open("https://fiftyfivetech.uk/build-an-mvp/", "_blank");
              }}
            >
              Find out more
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              backgroundColor: "#3C3ED4",
              color: "#fff",
              textAlign: "center",
              padding: "4rem",
              height: "30vh",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Hire our Developers
            </Typography>
            <Typography variant="body1" sx={{ my: 2, height: "5vh" }}>
              Build your dream in-house team to work on new or existing projects
              in as little as 1 week.
            </Typography>
            <Button
              sx={{
                backgroundColor: "#3C3ED4",
                border: "1px solid white",
                color: "#fff",
                textAlign: "center",
                borderRadius: "8px",
                mt: 2,
              }}
              onClick={() => {
                window.open(
                  "https://fiftyfivetech.uk/hire-a-developer/ ",
                  "_blank"
                );
              }}
            >
              Find out more
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        sx={{ mt: 4, width: "100%", maxWidth: "1200px" }}
      >
        {/* Service Offerings Section */}
        {[
          {
            title: "Software & Web Development",
            description:
              "With expertise in 80+ front and back end technologies, talk to us about your next idea.",
          },
          {
            title: "Mobile App Development",
            description:
              "High-performing iOS and Android app development for new or existing projects.",
          },
          {
            title: "AI & ML Integration",
            description:
              "Outpace your competition by integrating AI and Machine Learning into your products.",
          },
        ].map((service, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: "center",
                height: "25vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {service.title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {service.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f5f7fa",
          p: 3,
          borderRadius: 2,
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Box sx={{ flex: 1, width: "100%", maxWidth: "700px" }}>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "medium", mt: 2 }}>
            Book a free consultation with one of our Project Managers to see how
            we can support your tech development requirements.
          </Typography>
        </Box>

        <Box
          sx={{
            ml: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              borderRadius: "5px",
              height: "40px",
              backgroundColor: "#2468FF",
              color: "#ffffff",
              marginTop: "1rem",
              marginLeft: "0.5rem",
              mb: 2,
              fontWeight: 600,
              padding: "1rem 2rem",
              textTransform: "none",
              width: "max-content",
              "&:hover": {
                backgroundColor: "#b4a4f5",
              },
            }}
            onClick={() => {
              window.open(
                "https://meetings.hubspot.com/matt2751/55tech-meeting",
                "_blank"
              );
            }}
          >
            Book a call
          </Button>

          <Box sx={{ display: "flex", gap: 1 }}>
            <img src={FiftyFiveTeam} alt="FiftyFiveTeam" width={"200px"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SoftwarePage;
