import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { sendOtp } from "../../apiServices/services/CheckoutServices";
import { getcountries } from "../../apiServices/services/CompanyServices";
import BackButton from "../../assets/logo/BackButton.svg";
import { countries } from "../../constants/countries";

const AccountCreationForm = ({ handleClickSetSeven, handlePrevious }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [countryCodes, setCountriesCode] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { firstName, lastName, email, phoneNumber, password } = formData;
    if (!firstName || !lastName || !email || !phoneNumber || !password) {
      alert("Please fill all the details before proceeding forward.");
      return;
    }
    const data = {
      mobileNumber: selectedCountry.code + formData.phoneNumber,
      additionalDetails: "Some additional information if needed",
    };
    const formDatas = {
      ...formData,
      phoneNumber: selectedCountry.code + formData.phoneNumber,
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "account_creation",
      userData: {
        name: formData.name,
        email: formData.email,
        timestamp: new Date().toISOString(),
      },
    });
    localStorage.setItem("registeredEmail", formData.email);
    sendOtp(data).then((res) => {
      if (res.status === 200) {
        console.log(res);
        handleClickSetSeven(formDatas);
      }
    });
  };

  useEffect(() => {
    const getcountries = async () => {
      // const response = await fetch("https://restcountries.com/v3.1/all");
      const allCountries = Array.isArray(countries)
        ? countries
        : Object.values(countries);

      const countryCodes = allCountries.map((country) => {
        const iddRoot = country?.idd?.root || ""; // Check if `idd.root` exists
        const iddSuffix = country?.idd?.suffixes?.[0] || ""; // Check if the first suffix exists

        return {
          label: country.name.common,
          code: iddRoot + iddSuffix, // Concatenate root and suffix safely
          flag: country.flags?.png || country.flags?.svg, // Use PNG or SVG flag image
        };
      });

      setCountriesCode(countryCodes);
      setSelectedCountry(countryCodes[47]); // Set the first country as default
    };

    getcountries();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: { xs: "0", md: "4rem" },
        paddingTop: { xs: "1rem", md: "2rem" },
        maxWidth: { xs: "450px", md: "1150px" },
      }}
      id="account-creation-form"
    >
      <Box>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          Create an account to incorporate your business
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: "1.2rem",
            color: "#5A5A5A",
            mb: 8,
            maxWidth: "800px",
          }}
        >
          After creating an account you can checkout and start the incorporation
          process.
        </Typography>
        <Box>
          <Box sx={{ display: "flex", gap: "1rem", width: "100%", mb: 2 }}>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "18px" }}
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
            />
          </Box>

          {/* Email Field */}
          <TextField
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            type="email"
            sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <Box sx={{ display: "flex", alignItems: "center", mb: "2rem" }}>
            <Autocomplete
              options={countryCodes}
              getOptionLabel={(option) => `${option.label} (${option.code})`} // Label will not include the flag
              value={selectedCountry}
              onChange={handleCountryChange} // Update selected country on change
              isOptionEqualToValue={(option, value) =>
                option.code === value?.code
              } // Prevent value mismatch
              renderOption={(props, option) => (
                <li {...props}>
                  <img
                    src={option.flag}
                    alt={option.label}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  {option.label} ({option.code})
                </li>
              )}
              sx={{
                width: 250,
                mr: 2,
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              renderInput={(params) => (
                <TextField {...params} label="Code" variant="outlined" />
              )}
            />

            <TextField
              label="Mobile Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              type="number"
              sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
            />
          </Box>

          <Box sx={{ display: "flex", width: "100%" }}>
            <Button
              // variant="contained"
              onClick={handlePrevious}
              sx={{
                color: "#000",
                fontSize: "1rem",
                textTransform: "none",
                marginBottom: "0.75rem",
                marginRight: "1rem",
              }}
            >
              <img width={"70px"} src={BackButton} />
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "blue",
                borderRadius: "15px",
                fontSize: "1.25rem",
                fontWeight: "bold",
                textTransform: "none",
                marginBottom: "0.75rem",
                ":hover": {
                  backgroundColor: "#181e54",
                },
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountCreationForm;
